import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import BlogPostsFilter from "../sections/BlogPosts/BlogPostsFilter";
import BlogPostsList from "../sections/BlogPosts/BlogPostsList";
import Pagination from "../components/Pagination";
import Newsletter from "../components/Newsletter";
import DefaultHelmet from "../seo/DefaultHelmet";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";

const BlogPosts = ({ pageContext }) => {
	const {
		categories,
		posts,
		currentPage,
		pagesCount,
		categoryOriginPath,
		currentCategorySlug,
		pageTitle,
		pageHeader,
	} = pageContext;

	useBeaconWidget(beaconWidgetIds.GENERAL);

	return (
		<DefaultLayout isHeaderWithBlackText={true}>
			<DefaultHelmet
				title={pageTitle + " | Blog page " + currentPage}
				description={"UTD Blog, category: " + pageTitle + ", page: " + currentPage}
				keywords={"UTD, UTD Blog, eCommerce, eCommerce news"}
			/>

			<BlogPostsFilter
				title={pageTitle}
				categories={categories}
				currentCategorySlug={currentCategorySlug}
				pageHeader={pageHeader}
			/>

			<BlogPostsList
				posts={posts}
			/>

			<Pagination
				pagesCount={pagesCount}
				currentPage={currentPage}
				originalPath={categoryOriginPath}
				containerClasses={"container--medium"}
			/>

			<div className="blog-posts-newsletter">
				<Newsletter />
			</div>
		</DefaultLayout>
	);
};

export default BlogPosts;
