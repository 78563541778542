import React from "react";
import PropTypes from "prop-types";
import PostCard from "../../components/PostCard";

const BlogPostsList = ({ posts }) => {
	return (
		<div className="blog-posts-list">
			<div className="container container--medium container--mobile-small blog-posts-list">
				{posts.map(post => (
					<div className="blog-posts-list__item" key={post.id}>
						<PostCard
							titleTag={"h2"}
							title={post.title}
							categories={post.categories}
							date={post.date}
							link={post.link}
							squaredImage={post.acfPost?.squaredImage}
							excerpt={post.acfPost?.excerpt}
							isRow={true}
						/>
					</div>
				))}
			</div>
		</div>
	);
}

BlogPostsList.propTypes = {
	posts: PropTypes.array.isRequired,
};

export default BlogPostsList;
